/* ========== policy ========== */
.policy{
  margin-top: 80px;
}
.policy h2{
  font-size: 1.5em;
}
.policy h4{
  font-size: 1.5em;
}
.policy tr{
  border-bottom: 1px solid #666;
}
.policy th{
  width: 40%;
  border: none;
}
.policy td{
  width: 60%;
  border: none;
}
/* ========== /policy ========== */
/* ========== company-profile ========== */
.page__company-profile table{
  width: 100%;
}
.page__company-profile th{
  position: relative;
  text-align: left;
  width: 25%;
  border-style: none;
}
.page__company-profile td{
  text-align: left;
  width: 85%;
  border-style: none;
}
/* ========== /company-profile ========== */
/* ========== contact ========== */
  @media only screen and (min-width: 750px) {
    .page__contact{
      width: 520px;
      margin: 0 auto;
    }
  }
.contact__thanks{
  text-align: center;
  font-size: 18px;
  font-weight: 700;
}
.contact__thanks p{
  margin: 55px 0;
}
.rte a:not(.btn) {
  border-bottom: 0!important;
  padding-bottom: 0!important;
  }
.contact__thanks a{
  display: block;
  width:300px;
  height:70px;
  line-height:70px;
  margin: 0 auto;
  border-radius: 8px;
}
a.button__faq{
  color: #fff;
  background: #108B14;
}
a.button__faq:hover{
  color: #108B14!important;
  background: #fff;
  border: 3px solid #108B14!important;
}
a.button__backtotop{
  margin-top: 16px;
  color: #108B14;
  background: #fff;
}
a.button__backtotop:hover{
  color: #1A1311!important;
}
/* ========== /contact ========== */
/* ========== faq ========== */
.page__faq h2{
  font-size:32px;
}
span.question{
  display: block;
  color: #ED6B00;
  font-size: 18px;
  font-weight: 600;
}
span.question::before{
  content: 'Q.';
}
/* ========== /faq ========== */
/* ========== landing-page-content ========== */
.landeing-page-wrapper{
}
  @media only screen and (min-width: 750px) {
    .landeing-page-wrapper{
} }
/* ========== landing-page-content ========== */
